// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const origin = window.location.origin;
const envSetup = true;

var realm = 'aaamb'
var keycloakClientId = 'aaamb-web-client'

const HTTP  = 'https://';
var DevHTTP  = '';

if(origin.includes('dev')){
  DevHTTP  = HTTP + 'dev.';
  realm = 'dev';
  keycloakClientId = 'dev-aaamb-web-client';
} else if(origin.includes('uat')){
  DevHTTP  = HTTP + 'uat.';  
  realm = 'uat';
  keycloakClientId = 'uat-aaamb-web-client';
} else if(origin.includes('localhost')){
  DevHTTP  = HTTP + 'localhost.';
  realm = 'dev';
  keycloakClientId = 'dev-aaamb-web-client';
} else {
  realm = 'aaamb';
  DevHTTP  = HTTP + 'app.';
}

export const environmentNew = {
  production: false,
  //apis
  serviceAPI: DevHTTP + 'credentialtoday.com/api',            //ct
  mainServiceAPI: DevHTTP + 'admin.aaamb.com/api',   
  npiRegistryAPI: 'https://npiregistry.cms.hhs.gov/api/?version=2.1',
  adminMail:'credentialtoday@aaamb.com', // for contact email triggering.
  kcHost: HTTP + 'auth.aaamb.com',
  logoutUrl:  DevHTTP + 'admin.aaamb.com/login',
  realm: realm,
  keycloakClientId:keycloakClientId
};

export const environmentOld = {
  production: false,
  serviceAPI: 'http://3.6.5.222:8005',
  mainServiceAPI: 'http://3.6.5.222:8010',
  npiRegistryAPI: 'https://npiregistry.cms.hhs.gov/api/?version=2.1',
  adminMail:'credentialtoday@aaamb.com', // for contact email triggering.
  kcHost:'http://3.6.5.222:8080',
  logoutUrl:'http://3.6.5.222:8009//login',
  realm: realm,
  keycloakClientId:keycloakClientId
};

export const environment = envSetup ? environmentNew : environmentOld;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
