import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig} from '@angular/router';
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { DropdownModule, SidebarModule } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';
import { NgbAccordionModule, NgbModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { KeycloakAngularModule,KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./core/guards/app.init";
import { provideToastr } from 'ngx-toastr';
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      }),
      //withEnabledBlockingInitialNavigation(),
      // withViewTransitions(),
      // withHashLocation()
    ),

    importProvidersFrom(CommonModule,ReactiveFormsModule,FormsModule,
      KeycloakAngularModule,
      SidebarModule, DropdownModule,
      NgbAccordionModule,
      NgbNavModule,
      NgbTooltipModule,
      NgbModule),
      provideAnimations(),
      provideToastr({
        timeOut: 5000,
        closeButton: true,
        progressBar: true,
        positionClass:'toast-top-center',
        preventDuplicates:true
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
        provide:HTTP_INTERCEPTORS,
        useClass:JwtInterceptor,
        multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ErrorInterceptor,
      multi:true
    },
    IconSetService,
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    DecimalPipe,
  ]
};
