import { environment } from "src/environments/environment";

export const APP_CONSTANTS = {
  cookiekey:  'aaamb-client',
  cookieexpiry: '7',
  imgurl: environment.mainServiceAPI,
  USPS_ID: "05WAAAMB72833",
  USPS_REV: 1,
  homeUrl: window.location.origin
};
