<!--<c-footer>-->
  <div>

    <a href="https://website.com/" target="_blank">AAAMB</a>
    <span> &copy; 2024 AAAMB</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://website.com/angular" target="_blank">
      <span> AAAMB Admin</span>
    </a>
  </div>
<!--</c-footer>-->
