import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';


export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
        keycloak.init({
            config: {
                url: environment.kcHost,
                realm: environment.realm,
                clientId: environment.keycloakClientId,
            },
            initOptions: {
                checkLoginIframe: true,
                checkLoginIframeInterval: 25
            },
            // Enables Bearer interceptor
            //enableBearerInterceptor: true,
            // Prefix for the Bearer token
            //bearerPrefix: 'Bearer',
            loadUserProfileAtStartUp: true
        });
}
