import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Login, User, UserDetails } from "../models/auth.models";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUserData: BehaviorSubject<any>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserData = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    let login: Login = {
      email: email,
      password: password,
      applicationName: "CT",
    };
    return this.http
      .post<any>(environment.mainServiceAPI + `/user/login`, login)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user.data && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user.data));
            this.currentUserSubject.next(user.data);
          }
          return user;
        })
      );
  }

  getUsersDetails(id: string) {
    return this.http
      .get<UserDetails>(environment.mainServiceAPI + `/user/` + id)
      .pipe(
        map((user) => {
          this.currentUserData.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
   // this.currentUserSubject.next(""!);
  }


  // getting of the userId
  get currentUserId():string {
    const userId = JSON.parse(
      localStorage.getItem("currentUser")!
    )?.id;
    return userId;
  }
}
