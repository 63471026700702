import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    badge: {
      color: 'info',
      text: 'MAIN'
    }
  },
  {
    title: true,
    name: 'Main'
  },
  {
    name: 'User Type',
    url: '/common/usertype',
    iconComponent: { name: 'cil-drop' }
  },
  {
    name: 'Settings',
    url: '/common/settings',
    // linkProps: { fragment: 'headings' },
    iconComponent: { name: 'cil-pencil' }
  },
  {
    name: 'Products',
    title: true
  },
  {
    name: 'Credential Today',
    url: '/credential-today',
    iconComponent: { name: 'cil-puzzle' },
    children: [
      {
        name: 'Document Mapping',
        url: '/credential-today/document-mapping',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Payers',
        url: '/credential-today/payers',
        icon: 'nav-icon-bullet'
      },
      {
        name: 'Credential Services',
        url: '/credential-today/credential-services',
        icon: 'nav-icon-bullet'
      }
    ]
  }

];
