import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { AuthenticationService } from "../services/auth.service";
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
@Injectable({ providedIn: "root" })
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
      protected override readonly router: Router,
      protected readonly keycloak: KeycloakService
  ) {
      super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    const requiredRoles = route.data['roles'];
    // Allow the user to proceed if all the required roles are present.
    if (!requiredRoles.every((role: string) => this.roles.includes(role))) {
      this.router.navigate(['/403']);
      return false;
      // you can redirect unauthorized route to error page
    }
    else{
      return this.authenticated;
    }

  }
}

// @Injectable({ providedIn: "root" })
// export class AuthGuard implements CanActivate {
//   constructor(
//     private router: Router,
//     private authenticationService: AuthenticationService
//   ) { }

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     const currentUser = this.authenticationService.currentUserValue;
//     if (currentUser) {
//       // logged in so return true
//       return true;
//     }
//     // not logged in so redirect to login page with the return url
//     this.router.navigate(["/account/login"], {
//       queryParams: { returnUrl: state.url },
//     });
//     return false;
//   }
// }
