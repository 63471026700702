import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './views/pages/login/login.component';
export const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['MAIN_ADMIN'] },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes),
        canActivate: [AuthGuard],
        data: { roles: ['MAIN_ADMIN'] }
      },
      {
        path: 'credential-today',
        loadChildren: () => import('./views/credential-today/routes').then((m) => m.routes),
        canActivate: [AuthGuard],
        data: { roles: ['MAIN_ADMIN'] }
      },
      {
        path: 'common',
        loadChildren: () => import('./views/common/routes').then((m) => m.routes),
        canActivate: [AuthGuard],
        data: { roles: ['MAIN_ADMIN'] }
      }

    ]
  },
  {
    path: '403',
    loadComponent: () => import('./views/pages/page403/page403.component').then(m => m.Page403Component),
    data: {
      title: 'Page 403'
    }
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'register',
    loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  { path: '**', redirectTo: '403' }
];
